import React from 'react'

const DaysItem = ({ day } = {}) => {
    return (
        <li className="list-group-item day-item">
            <div className="week-day">{day.weekDay}</div>
            <div className="time-range-wrapper">
                {day.timeRanges.map((range, index) => (
                    <div className="time-range" key={index}>
                        {range.from}
                        {` - `}
                        {range.to}
                    </div>
                ))}
            </div>
        </li>
    )
}
export default DaysItem
