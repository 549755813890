import {NEWS_ERROR, NEWS_REQUEST, NEWS_PUSH, NEWS_TOTAL} from "./NewsTypes";

const initialState = {
    isFetching: false,
    error: '',
    articles: [],
    pagination: {
        totalPages: 1,
    },
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case NEWS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case NEWS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case NEWS_PUSH:
            const articleExists = state.articles.filter(({uuid, page}) => {
                return uuid === action.payload.uuid && page === action.payload.page
            }).length > 0

            if (articleExists) {
                return {
                    ...state,
                    isFetching: false,
                }
            }

            return {
                ...state,
                isFetching: false,
                articles: [...state.articles, action.payload],
            }
        case NEWS_TOTAL:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    totalPages: action.payload,
                },
            }
        default:
            return state
    }
}