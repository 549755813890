import { EXPERTS_SUCCESS, EXPERTS_ERROR, EXPERTS_REQUEST } from "./ExpertsTypes";
import Api from "../../utils/Api";

export const expertsRequest = () => ({
    type: EXPERTS_REQUEST,
})

export const expertsError = (error) => ({
    type: EXPERTS_ERROR,
    payload: error,
})

export const expertsSuccess = (payload) => ({
    type: EXPERTS_SUCCESS,
    payload
})

export const expertsAction = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        expertsRequest()
        api.get('experts', {
            user_id: state.page.user.uuid,
            page_id: state.page.page.uuid,
        }).then((response) => {
            if (Array.isArray(response.users)) {
                dispatch(expertsSuccess(response.users))
            }
        }).catch(e => {
            expertsError(e)
        })
    }
}