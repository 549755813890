import React from 'react'
import {connect} from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import Mock from '../Mock'

const Footer = ({page}) => {
    if (!page.uuid) {
        return <Mock/>
    }

    return (
        <footer className="app__footer">
            {ReactHtmlParser(page.footer)}
        </footer>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
})

export default connect(mapStateToProps)(Footer)
