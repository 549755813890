import Api from './utils/Api'

export const loadFromApi = () => {
    return (dispatch) => {
        dispatch(setDataReady(false))
        const api = new Api()
        api.get('index', { url: window.location.href })
            .then((pageData) => {
                dispatch(setPageData(pageData))
                if (Array.isArray(pageData.news)) {
                    pageData.news.forEach((article) => {
                        dispatch(addNewsArticle(article))
                    })
                }
                dispatch(setDataReady(true))
            })
            .catch(() => {
                dispatch(setDataError(true))
            })
    }
}

export const postReview = (reviewObject) => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        dispatch(setReviewPostProcessing(true))
        api.post('review', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
            username: reviewObject.userName,
            rate: reviewObject.userRate,
            content: reviewObject.userReview,
        })
            .then(({ message }) => {
                dispatch(setReviewPostMessage(message))
            })
            .catch((message) => {
                dispatch(setReviewPostMessage(message))
            })
            .finally(() => {
                dispatch(setReviewPostProcessing(false))
            })
    }
}

export const loadGallery = () => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('gallery', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
        }).then((responseData) => {
            if (responseData.length > 0) {
                dispatch(setGalleryImages(responseData))
            }
        })
    }
}

export const loadLastReviews = () => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('last_reviews', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
        }).then((responseData) => {
            if (responseData.length > 0) {
                dispatch(setLastReviews(responseData))
            }
        })
    }
}

export const loadExperts = () => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('experts', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
        }).then((responseData) => {
            if (Array.isArray(responseData.users)) {
                dispatch(setExperts(responseData.users))
            }
        })
    }
}

export const loadReviewsPage = (page_nr = 1, on_page = 2) => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('reviews', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
            on_page,
            page_nr,
        }).then((responseData) => {
            dispatch(setReviewsPaginationTotal(responseData.pages))
            if (Array.isArray(responseData.items)) {
                responseData.items.forEach((item) => {
                    dispatch(addReviewItem(item, page_nr))
                })
            }
        })
    }
}

export const loadNewsPage = (page_nr = 1, on_page = 2) => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('news', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
            on_page,
            page_nr,
        }).then((responseData) => {
            dispatch(setNewsPaginationTotal(responseData.pages))
            if (Array.isArray(responseData.items)) {
                responseData.items.forEach((article) => {
                    dispatch(addNewsArticle(article, page_nr))
                })
            }
        })
    }
}

export const loadArticle = (uuid, page = -1) => {
    return (dispatch, getState) => {
        dispatch(setNewsArticleFetchingStatus(true))
        dispatch(setNewsArticleFetchingError(false))

        const state = getState()
        const api = new Api()
        api.get('news_preview', {
            user_id: state.app.user.uuid,
            page_id: state.app.page.uuid,
            oid: uuid,
        }).then((responseData) => {
            if (responseData.news) {
                dispatch(addNewsArticle(responseData.news, page))
            } else {
                dispatch(setNewsArticleFetchingError(true))
            }
            dispatch(setNewsArticleFetchingStatus(false))
        })
    }
}

export const setPageData = (pageData) => ({
    type: 'SET_PAGE_DATA',
    pageData,
})

export const setDataError = (error = '') => ({
    type: 'SET_DATA_ERROR',
    error,
})

export const setDataReady = (status = false) => ({
    type: 'SET_DATA_READY',
    status,
})

export const setGalleryImages = (gallery) => ({
    type: 'SET_GALLERY_IMAGES',
    gallery,
})

export const setLastReviews = (reviews) => ({
    type: 'SET_LAST_REVIEWS',
    reviews,
})

export const setExperts = (experts) => ({
    type: 'SET_EXPERTS',
    experts,
})

export const setNewsArticleFetchingStatus = (status) => ({
    type: 'SET_NEWS_ARTICLE_FETCHING_STATUS',
    status,
})

export const setNewsArticleFetchingError = (error) => ({
    type: 'SET_NEWS_ARTICLE_FETCHING_ERROR',
    error,
})

export const setNewsPaginationTotal = (total) => ({
    type: 'SET_NEWS_PAGINATION_TOTAL',
    total,
})
export const addNewsArticle = (article, page = 1) => ({
    type: 'ADD_NEWS_ARTICLE',
    article: {
        ...article,
        page: parseInt(page),
    },
})

export const setReviewsPaginationTotal = (total) => ({
    type: 'SET_REVIEWS_PAGINATION_TOTAL',
    total,
})

export const addReviewItem = (review, page = 1) => ({
    type: 'ADD_REVIEW_ITEM',
    review: {
        ...review,
        page: parseInt(page),
    },
})

export const setReviewPostProcessing = (status = false) => ({
    type: 'SET_REVIEW_POST_PROCESSING',
    status,
})

export const setReviewPostMessage = (message = '') => ({
    type: 'SET_REVIEW_POST_MESSAGE',
    message,
})
