import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import {pageAction} from "./modules/actions";
import './utils/Translation'

import Sidebar from './components/Sidebar'
import NewsAll from './components/Container/NewsAll'
import NewsSingle from './components/Container/NewsSingle'
import ReviewsAll from './components/Container/ReviewsAll'
import CustomizePage from './components/CustomizePage'
import ErrorPage from './components/ErrorPage'
import Container from "./components/Container";
import Halodoctor from "./components/Halodoctor";

import './assets/scss/app.scss'
import './fontello/css/fontello.css'

const Router = ({pageAction, page, error}) => {
    const [showSidebar, setShowSidebar] = useState(true)

    useEffect(() => {
        if (!page.uuid) {
            pageAction()
        }
    }, [page])

    const renderSidebar = () => {
        if (page.id && Object.entries(page.data.days).length === 0
            && page.data.showOffices === '0'
            && page.data.showReviews === '0'
            && page.data.showServices === '0'
        ) {
            if (showSidebar) setShowSidebar(false)
            return null
        }
        if (!showSidebar) setShowSidebar(true)
        return <Sidebar/>
    }

    const appCssClass = ['app']
    if (!showSidebar) appCssClass.push('app--no-sidebar')

    return (
        <BrowserRouter>
            {error !== '' && <Redirect to="/404"/>}
            <CustomizePage/>
            <Switch>
                <Route>
                    <div className={appCssClass.join(' ')}>
                        <Switch>
                            <Route path="/" exact={true} component={Container}/>
                            <Route path="/news/view/:id" exact={true} component={NewsSingle}/>
                            <Route path="/news" exact={true} component={NewsAll}/>
                            <Route path="/news/:page" exact={true} component={NewsAll}/>
                            <Route path="/reviews" exact={true} component={ReviewsAll}/>
                            <Route path="/reviews/:page" component={ReviewsAll}/>
                            <Route component={ErrorPage} status={404}/>
                            {/*<Route path="/reviews/new" component={ReviewForm} exact={true} />*/}
                        </Switch>
                        {renderSidebar()}
                    </div>
                </Route>
            </Switch>
            <Halodoctor/>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => {
    return {
        page: state.page.page,
        error: state.page.error
    }
}

export default connect(mapStateToProps, {pageAction})(Router)
