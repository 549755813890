import React from 'react'

const PageTitle = ({ text = '', teal = '' }) => (
    <h3 className="page_title">
        {text}
        {teal && <>&nbsp;</>}
        <span className="text-teal">{teal}.</span>
    </h3>
)
export default PageTitle
