import React from 'react'
import Header from "../Header";
import Footer from "../Footer";
import '../../assets/scss/app.scss'
import '../../fontello/css/fontello.css'

export default ({children}) => {
    return (
        <>
            <Header/>
            <div className="">
                {children}
                <Footer/>
            </div>
        </>
    )
}