import React from 'react'
import { useTranslation } from 'react-i18next'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

const OfficeItem = ({ office } = {}) => {
    const { t } = useTranslation()
    const openPopup = (marker) => {
        if (marker && marker.leafletElement) {
            window.setTimeout(() => {
                marker.leafletElement.openPopup()
            })
        }
    }

    const renderMap = (office) => {
        if (!office.lat || !office.lng || !office.address_postal || !office.address_city) {
            return null;
        }

        return <Map center={[office.lat, office.lng]} zoom={13}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[office.lat, office.lng]} ref={openPopup}>
                <Popup>
                    <h2>{office.title}</h2>
                    <p>
                        {office.address_postal} {office.address_city}
                        <br />
                        {office.address_street} {office.address_home}
                        {office.address_local &&
                            ' / ' + office.address_local}
                    </p>
                </Popup>
            </Marker>
        </Map>
    }

    return (
        <div className="sidebar_offices_item">
            {renderMap(office)}
            <p>
                <strong>{office.title}</strong>
            </p>
            <ul className="">
                {office.address_city && (
                    <li>
                        <i className="icon icon-location text-teal">&nbsp;</i>
                        {office.address_postal}&nbsp;
                        {office.address_city}&nbsp;
                        {office.address_street}&nbsp;
                        {office.address_home}&nbsp;
                        {office.address_local && ' / ' + office.address_local}
                    </li>
                )}

                {office.phone && (
                    <li className="">
                        <i className="icon icon-phone text-teal">&nbsp;</i>
                        <a href={`tel:${office.phone}`}>
                            <span className="text-teal">
                                {t('sidebar:sidebarTel')}. {office.phone}
                            </span>
                        </a>
                    </li>
                )}

                {office.email && (
                    <li className="">
                        <i className="icon icon-mail text-teal">&nbsp;</i>
                        <a href={`mailto:${office.email}`}>
                            <span className="text-teal">{office.email}</span>
                        </a>
                    </li>
                )}
            </ul>
            <div>{office.description}</div>
        </div>
    )
}
export default OfficeItem
