import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NewsItem from './NewsItem'
import PageTitle from '../PageTitle'
import Mock from '../Mock'

const News = ({page, news}) => {
    const { t } = useTranslation()

    if (!page.uuid) {
        return (
            <div>
                <Mock type="title" unique={2}/>
                <div className="news__grid">
                    <Mock type="panel"/>
                    <Mock type="panel"/>
                </div>
            </div>
        )
    }

    if (news.length === 0) {
        return null
    }

    return (
        <>
            <div className="news">
                <PageTitle text={t('news:titleHome')}/>
                <div className="news__grid">
                    {news.filter(({page}, index) => page === 1 && index < 2
                        )
                        .map((news, index) => (
                            <NewsItem news={news} key={index}/>
                        ))}
                </div>
            </div>
            <div className="news__more">
                <Link className="text-link" to="/news/1">
                    {t('news:moreHome')}
                </Link>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    news: state.news.articles,
})

export default connect(mapStateToProps)(News)
