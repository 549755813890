import base64 from 'react-native-base64'
import { create } from 'apisauce'

class Api {
    Instance = undefined

    constructor() {

        //FOR FASTER DEVELOPMENT
        const getHeaders = () => {
            let headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }

            if (process.env.REACT_APP_ENV === 'dev') {
                return headers;
            }

            const {
                REACT_APP_API_USER: user,
                REACT_APP_API_PASSWORD: pass,
            } = process.env

            return {
                'Authorization': `Basic ${base64.encode(`${user}:${pass}`)}`,
                ...headers
            }
        }

        this.Instance = create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 5000,
            headers: getHeaders()
        })
    }

    getQueryString = (queryData = {}) => {
        const queryParams = new URLSearchParams()
        Object.entries(queryData).forEach(([key, value]) => {
            queryParams.append(key, value)
        })
        const searchString = queryParams.toString()
        return searchString && `?${searchString}`
    }

    isValidResponse = (response) => {
        return (response.data.status && response.data.status === 1)
    }

    get = (url, data = {}) => {
        return new Promise((resolve, reject) => {
            this.Instance.get(url + this.getQueryString(data))
                .then(response => {
                    const {data} = response;
                    if (!this.isValidResponse(response)) {
                        reject(data.message)
                    }
                    resolve(data.data)
                }).catch(reject)
        })
    }
    post = (url, body = {}) => {
        return new Promise((resolve, reject) => {
            this.Instance.post(url, {body})
                .then(response => {
                    const {data} = response;
                    if (!this.isValidResponse(response)) {
                        reject(data.message)
                    }
                    resolve(data.data)
                }).catch(reject)
        })
    }
}

export default Api
