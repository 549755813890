import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import '../assets/scss/Pagination.scss'

const Pagination = ({ url, pages, current }) => {
    const pagesLinks = ((url, pages, current) => {
        const output = []
        for (let index = 1; index <= pages; index++) {
            const cssClass = ['pagination__page']
            index === current && cssClass.push('pagination__page--active')
            output.push(
                <NavLink
                    className={cssClass.join(' ')}
                    key={index}
                    to={`${url}/${index}`}
                >
                    {index}
                </NavLink>
            )
        }
        return output
    })(url, pages, current)

    return (
        <div className="pagination">
            <Link
                className="pagination__control pagination--prev"
                to={`${parseInt(current) === 1 ? 1 : parseInt(current) - 1}`}
            >
                &lt;
            </Link>
            <div className="pagination__pages">{pagesLinks}</div>
            <Link
                className="pagination__control pagination--next"
                to={`${
                    parseInt(current) === pages ? pages : parseInt(current) + 1
                }`}
            >
                &gt;
            </Link>
        </div>
    )
}
Pagination.defaultProps = {
    url: '/',
    pages: 1,
}

export default Pagination
