import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import AppRouter from './AppRouter'
import configureStore from './store'

import { loadFromApi } from './actions'
// import * as serviceWorker from './serviceWorker';
const store = configureStore()

const appRoot = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
)
store.dispatch(loadFromApi())

ReactDOM.render(appRoot, document.getElementById('container'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
