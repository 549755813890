import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const Language = () => {
    const { t, i18n } = useTranslation()

    const [language, setLanguage] = useState((localStorage.getItem("lang") ?? 'pl'))

    const onClickHandle = () => {
        if (language === 'pl') {
            setLanguage('en')
            localStorage.setItem("lang", "en")
        } else {
            setLanguage('pl')
            localStorage.setItem("lang", "pl")
        }
    }

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language])

    return (
        <div
            className="text-right text-muted header_language"
            onClick={onClickHandle}
        >
            <span>
                <i className="icon icon-globe">&nbsp;</i>
                {t('language:label')}
            </span>
        </div>
    )
}

export default Language
