import React from 'react'
import {connect} from 'react-redux'
import Mock from '../Mock'

const Logo = ({page}) => {
    if (!page.uuid) {
        return (
            <div>
                <Mock type="title" words={2}/>
                <Mock type="title" unique={5}/>
            </div>
        )
    }

    return (
        <div className="app__title">
            <h1 className="header_title">{page.title}</h1>
            <div className="text-teal">
                {page.data.specializations && page.data.specializations.join(', ')}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {page} = state;
    return {
        page: page.page
    }
}

export default connect(mapStateToProps)(Logo)
