import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { pl } from './pl'
import { en } from './en'

const resources = {
    pl,
    en,
}

i18n.use(initReactI18next).init({
    resources,
})

export default i18n
