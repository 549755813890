import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'

const RegisterButton = ({page, user}) => {
    const {t} = useTranslation()

    if (!page.uuid) {
        return null
    }

    let button = null

    if (page.data.register_online > 0) {
        button = (
            <a
                href="/#medfile-register-widget"
                className="header_register_button"
            >
                <i className="icon-calendar icon">&nbsp;</i>
                {t('header:registerVisit')}
            </a>
        )
    } else {
        button = (
            <a
                href="/#medfile-register-widget"
                className="header_register_button"
                title="<?= h($this->t('Chcesz zapisać się na wizytę? Zgłoś swojemu lekarzowi, aby udostępnił rejestrację on-line.')) ?>"
            >
                <i className="icon-calendar icon">&nbsp;</i>
                {t('header:registerVisit')}
            </a>
        )
    }

    return <div className="header_register">{button}</div>
}

const mapStateToProps = (state) => {
    const {page} = state;
    return {
        user: page.user,
        page: page.page,
    }
}

export default connect(mapStateToProps)(RegisterButton)
