import React from 'react'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import PageTitle from '../PageTitle'
import Mock from '../Mock'

const About = ({page}) => {
    const { t } = useTranslation()

    if (!page.uuid) {
        return (
            <div>
                <Mock type="title" words={1} />
                <Mock type="text" words="100" />
            </div>
        )
    }

    if (!page.content) {
        return null
    }

    return (
        <div>
            <PageTitle text={t('container:aboutTitle')} />
            {ReactHtmlParser(page.content)}
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
})

export default connect(mapStateToProps)(About)
