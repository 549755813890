import React from 'react'
import {connect} from 'react-redux'
import Mock from '../Mock'

const Logo = ({page, user}) => {
    if (!page.uuid) {
        return <Mock type="image"/>
    }

    if (!page.data.logo_image) {
        return null
    }

    const url = `${process.env.REACT_APP_ASSETS}${process.env.REACT_APP_DEFAULT_ASSETS_PATH}${user.uuid}/expert_photo/${page.data.logo_image}`

    if (!user.catalog_link) {
        return <img src={url} alt={page.title}/>
    }

    return (
        <a
            href={user.catalog_link}
            className="banner"
            target="_blank"
            rel="noopener"
        >
            <img src={url} alt={page.title}/>
        </a>
    )
}

const mapStateToProps = (state) => {
    const {page} = state;
    return {
        user: page.user,
        page: page.page,
    }
}

export default connect(mapStateToProps)(Logo)
