import {combineReducers} from 'redux'
import PageReducer from "./Page/PageReducer";
import NewsReducer from "./News/NewsReducer";
import ExpertsReducer from "./Experts/ExpertsReducer";
import GalleryReducer from "./Gallery/GalleryReducer";
import ReviewsReducer from "./Reviews/ReviewsReducer";

export default combineReducers({
    page: PageReducer,
    news: NewsReducer,
    experts: ExpertsReducer,
    gallery: GalleryReducer,
    reviews: ReviewsReducer
})
