import React from 'react'
import specialistIcon from '../../assets/img/specjalist-icon.png'

const ExpertItem = ({ expert = {} }) => {
    const {
        photo,
        alias,
        link,
        name,
        lastname,
        academic_title,
        specialization,
    } = expert
    return (
        <div className="expert_photo">
            <div className="expert_photo_body">
                {photo !== '' && photo ? (
                    <img src={process.env.REACT_APP_ASSETS + photo} alt={alias} />
                ) : (
                    <img src={specialistIcon} alt={alias} />
                )}
            </div>
            <div className="expert_photo_footer">
                {link ? (
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <span className="text-teal">
                            {name}&nbsp;{lastname}
                        </span>
                    </a>
                ) : (
                    <span className="text-muted">
                        {name}&nbsp;{lastname}
                    </span>
                )}
                <div className="text-muted text-center">{academic_title}</div>
                {specialization && (
                    <div className="text-muted text-center">
                        {specialization}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ExpertItem
