import {REVIEWS_ERROR, REVIEWS_PUSH, REVIEWS_REQUEST, REVIEWS_TOTAL} from "./ReviewsType";

const initialState = {
    isFetching: false,
    error: '',
    items: [],
    pagination: {
        totalPages: 1,
    },
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case REVIEWS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case REVIEWS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case REVIEWS_PUSH:
            const reviewsExists = state.items.filter(({created_at}) => {
                return created_at === action.payload.created_at
            }).length > 0

            if (reviewsExists) {
                return {
                    ...state,
                    isFetching: false,
                }
            }

            return {
                ...state,
                isFetching: false,
                items: [...state.items, action.payload],
            }
        case REVIEWS_TOTAL:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    totalPages: action.payload,
                },
            }
        default:
            return state
    }
}