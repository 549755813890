import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useParams, Redirect} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'
import ReactHtmlParser from 'react-html-parser'
import Mock from '../Mock'
import {loadArticle} from '../../actions'
import ScrollTo from '../ScrollTo'
import dayjs from 'dayjs'
import Page from "./Page";

const NewsSingle = ({page, articles}) => {
    const {t} = useTranslation()
    const {id: articleUuid} = useParams()
    const articleDefault = {
        uuid: '',
        title: '',
        lead: '',
        text: '',
        date: '',
    }
    const [articleContent, setArticleContent] = useState(articleDefault)
    const [scroll, setScroll] = useState(false)

    const findArticle = (uuid, articles = []) => {
        const result = articles.filter((article) => article.uuid === uuid)
        return result.length ? result.pop() : null
    }

    const scrollTop = () => {
        setScroll(true)
        setTimeout(() => {
            setScroll(false)
        }, 10)
    }

    useEffect(() => {
        if (!page.uuid) {
            return;
        }

        if (!articles.length) {
            return
        }

        if (!articleContent.uuid || articleContent.uuid !== articleUuid) {
            const article = findArticle(articleUuid, articles)
            if (article) {
                setArticleContent(article)
            }

        }


    }, [page, articles, articleUuid])

    useEffect(() => {
        scrollTop()
    }, [])


    if (!page.uuid || !articles.length) {
        return <Mock type="panel"/>
    }

    if (page.data.show_news === '0') {
        return <Redirect to="/"/>
    }

    return (
        <Page>
            <div className="single-article">
                <ScrollTo scroll={scroll}/>
                <div className="link-wrapper">
                    <Link className="text-link" to="/news/1">
                        {t('news:backSingle')}
                    </Link>
                </div>

                <div className="news__item">
                    <h3 className="news__item__title">
                        {ReactHtmlParser(articleContent.title)}
                    </h3>

                    <span className="news__item__date">
                        {dayjs(articleContent.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')}
                   </span>

                    {articleContent.lead && (
                        <p className="lead">
                            <strong>{articleContent.lead}</strong>
                        </p>
                    )}
                    {articleContent.text && (
                        <>{ReactHtmlParser(articleContent.text)}</>
                    )}
                </div>

            </div>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    articles: state.news.articles,
})

export default connect(mapStateToProps)(NewsSingle)
