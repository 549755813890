import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'
import Mock from '../Mock'
import {reviewsLoadPageAction} from '../../modules/actions'
import ReviewItem from './ReviewItem'
import Pagination from '../Pagination'
import ScrollTo from '../ScrollTo'
import Page from "./Page";

const ReviewsAll = ({page, reviews, pagination, user, reviewsLoadPageAction}) => {
    const {t} = useTranslation()
    const {page: currentPage = 1} = useParams()
    const reviewsPerPage = 100

    const [reviewsFiltered, setReviewsFiltered] = useState([])
    const [scroll, setScroll] = useState(false)

    const getReviewsOnPage = (page, reviews) => {
        return reviews.filter((review) => review.page === parseInt(page))
    }

    const scrollTop = () => {
        setScroll(true)
        setTimeout(() => {
            setScroll(false)
        }, 10)
    }

    useEffect(() => {
        if (!page.uuid) {
            return;
        }


        const reviewsExists = reviews.filter(
            (review) => review.page === parseInt(currentPage)
        )
        if (reviewsExists.length < reviewsPerPage) {
            reviewsLoadPageAction(currentPage, reviewsPerPage)
        }

        setReviewsFiltered(getReviewsOnPage(currentPage, reviews))


    }, [page, reviews, currentPage])

    // useEffect(() => {
    //     if (props.dataReady) {
    //         scrollTop()
    //         const reviewsExists = props.reviews.filter(
    //             (review) => review.page === parseInt(currentPage)
    //         )
    //         if (reviewsExists.length < reviewsPerPage) {
    //             props.dispatch(loadReviewsPage(currentPage, reviewsPerPage))
    //         }
    //     }
    // }, [currentPage])
    //
    // useEffect(() => {
    //     if (!props.dataReady) {
    //         return
    //     }
    //     props.dispatch(loadReviewsPage(currentPage, reviewsPerPage))
    // }, [props.dataReady])
    //
    // useEffect(() => {
    //     setReviewsFiltered(getReviewsOnPage(currentPage, props.reviews))
    // }, [currentPage, props.reviews])

    useEffect(() => {
        scrollTop()
    }, [])

    if (!page.uuid) {
        return (<>
            {Array.from(Array(reviewsPerPage)).map((el, index) => (
                <Mock type="panel" key={index}/>
            ))}
        </>)
    }

    return (
        <Page>
            <ScrollTo scroll={scroll}/>
            <div className="link-wrapper" style={{textAlign: "right"}}>
                <Link className="text-link" to="/">
                    {t('reviews:backHome')}
                </Link>
            </div>
            <div className="panel">
                <PageTitle text={t('reviews:titleReviews')} teal={t('reviews:titleReviewsTeal')}/>
                <div className="reviews-grid">
                    {reviewsFiltered.map((review, index) => (
                        <ReviewItem
                            review={{
                                ...review,
                                link: user.catalog_link,
                                title: page.title,
                            }}
                            key={index}
                        />
                    ))}
                    {reviewsFiltered.length < reviewsPerPage &&
                    parseInt(currentPage) !== pagination.totalPages && (
                        <>
                            {Array.from(
                                Array(
                                    reviewsPerPage - reviewsFiltered.length
                                )
                            ).map((el, index) => (
                                <Mock type="panel" key={index}/>
                            ))}
                        </>
                    )}
                </div>
            </div>

            {pagination.totalPages > 1 && (
                <Pagination
                    pages={pagination.totalPages}
                    url="/reviews"
                    current={parseInt(currentPage)}
                />
            )}
        </Page>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    reviews: state.reviews.items,
    pagination: state.reviews.pagination,
    user: state.page.user,
})

export default connect(mapStateToProps, {reviewsLoadPageAction})(ReviewsAll)
