import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link, useParams, Redirect} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import NewsItem from './NewsItem'
import PageTitle from '../PageTitle'
import Mock from '../Mock'
import Pagination from '../Pagination'
import {newsAction} from "../../modules/actions";
import ScrollTo from '../ScrollTo'
import Page from "./Page";

const NewsAll = ({userPage, articles, totalPages, statePage, newsAction}) => {
    const {t} = useTranslation()
    const {page: currentPage = 1} = useParams()
    const articlesPerPage = 2

    const [isFetched, setIsFetched] = useState(false)
    const [fetchedPage, setFetchedPage] = useState(currentPage);
    const [articlesFiltered, setArticlesFiltered] = useState([])
    const [scroll, setScroll] = useState(false)

    const getNewsOnPage = (page, articles) => {
        return articles.filter((article) => article.page === parseInt(page))
    }

    const scrollTop = () => {
        setScroll(true)
        setTimeout(() => {
            setScroll(false)
        }, 10)
    }

    useEffect(() => {
        if (!userPage.uuid) {
            return
        }
        if(!isFetched || fetchedPage !== currentPage) {
            newsAction(currentPage, articlesPerPage)
            setIsFetched(true)
            setFetchedPage(currentPage)
        }
    }, [statePage, userPage, currentPage])

    useEffect(() => {
        setArticlesFiltered(getNewsOnPage(currentPage, articles))
    }, [currentPage, articles])

    useEffect(() => {
            scrollTop()
    }, [])

    if (!userPage.uuid) {
        return (
            <div className="news__grid">
                {Array.from(Array(articlesPerPage)).map((el, index) => (
                    <Mock type="panel" key={index}/>
                ))}
            </div>
        )
    }

    if (!articles.length) {
        return <Redirect to="/" />
    }

    return (
        <Page>
            <ScrollTo scroll={scroll}/>
            {userPage.data.show_news === '0' && <Redirect to="/"/>}
            <PageTitle text={t('news:titleHome')}/>
            <div className="link-wrapper">
                <Link className="text-link" to="/">
                    {t('news:backNews')}
                </Link>
            </div>
            <div className="news__grid">
                {articlesFiltered.map((news, index) => (
                    <NewsItem news={news} key={index}/>
                ))}

                {articlesFiltered.length < articlesPerPage &&
                parseInt(currentPage) !== totalPages && (
                    <>
                        {Array.from(
                            Array(
                                articlesPerPage -
                                articlesFiltered.length
                            )
                        ).map((el, index) => (
                            <Mock type="panel" key={index}/>
                        ))}
                    </>
                )}
            </div>
            {totalPages > 1 && (
                <Pagination
                    pages={totalPages}
                    url="/news"
                    current={parseInt(currentPage)}
                />
            ) && (
                <Pagination
                    pages={totalPages}
                    url="/news"
                    current={parseInt(currentPage)}
                />
            )}
        </Page>
    )
}

const mapStateToProps = (state) => {
 return {
    userPage: state.page.page,
    articles: state.news.articles,
    statePage: state.news.pagination.currentPage,
    totalPages: state.news.pagination.totalPages,
 }
}

export default connect(mapStateToProps, {newsAction})(NewsAll)
