import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import RatingStars from '../RatingStars'

const Reviews = ({page, review_data}) => {
    const {t} = useTranslation()

    let counter = 0;
    const [showList, setShowList] = useState(false);

    if (!page.uuid) {
        return null
    }

    if (!review_data.length) {
        return <div className="sidebar_reviews">
            <p>{t('sidebar:reviewEmpty')}</p>
        </div>
    }

    return (
        <div className="sidebar_reviews">
            {
                review_data.map((item, index) => {
                    if (Number(item.count) === 0 ) {
                        return null;
                    }

                    if (!showList && Number(counter) === 10) {
                        return null
                    }

                    counter++

                    return  (
                        <div key={index}>
                            <div className="reviews-rating">
                                <RatingStars rating={Math.round(item.average)}/>
                                <div>
                                <strong>
                                    {Number(item.average)} /
                                    {item.count} {
                                        Number(item.owner) === 1
                                            ? t('sidebar:facilityReview')
                                            : t('sidebar:reviewCount') + ' ' + item.name
                                    }
                                </strong>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            {counter > 9 && !showList
                && <div style={{fontSize: 12, textAlign: "left", cursor: "pointer"}} onClick={() => {setShowList(!showList)}}>
                    {t('sidebar:more')}
                </div>
            }
            {/*<Link className="sidebar_reviews_button" to="/reviews/new">*/}
            {/*    <i className="icon icon-plus"></i>*/}
            {/*    {t('sidebar:reviewAdd')}*/}
            {/*</Link>*/}
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    review_data: state.page.review_data,
})

export default connect(mapStateToProps)(Reviews)
