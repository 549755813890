import React from 'react'
import { connect } from 'react-redux'
import Banner from './Container/Banner'
import About from './Container/About'
import News from './Container/News'
import Registration from './Container/Registration'
import Experts from './Container/Experts'
import Gallery from './Container/Gallery'
import ReviewsLast from './Container/ReviewsLast'
import Page from './Container/Page'

const Container = ({ user, page }) => {
    return (
        <Page>
            <Banner />
            <About />
            {Number(page.data.show_news) > 0 && <News />}
            {page.data.register_online === true && user.uuid !== '' && (
                <Registration />
            )}
            {Number(page.data.show_experts) > 0 && <Experts />}
            {Number(page.data.show_offices_photo) > 0 && <Gallery />}
            {Number(page.data.show_reviews) > 0 && <ReviewsLast />}
        </Page>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.page.user,
        page: state.page.page,
    }
}

export default connect(mapStateToProps)(Container)
