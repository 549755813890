import {REVIEWS_TOTAL, REVIEWS_PUSH, REVIEWS_ERROR, REVIEWS_REQUEST} from "./ReviewsType";
import Api from "../../utils/Api";
import {newsTotal} from "../News/NewsAction";

export const reviewsRequest = () => ({
    type: REVIEWS_REQUEST,
})

export const reviewsError = (error) => ({
    type: REVIEWS_ERROR,
    payload: error,
})

export const reviewsTotal = (total) => ({
    type: REVIEWS_TOTAL,
    payload: total,
})

export const reviewsPush = (article, page = 1) => ({
    type: REVIEWS_PUSH,
    payload: {
        ...article,
        page: parseInt(page),
    }
})

export const reviewsLoadLastAction = () => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        reviewsRequest()
        api.get('/last_reviews', {
            user_id: state.page.user.uuid,
            page_id: state.page.page.uuid,
        }).then((response) => {
            if (response.length > 0) {
                 response.forEach((review) => {
                    dispatch(reviewsPush(review, 1))
                })
            }
        }).catch(e => {
            reviewsError(e)
        })
    }
}

export const reviewsLoadPageAction = (page_nr = 1, on_page = 2) => {
    return (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        api.get('reviews', {
            user_id: state.page.user.uuid,
            page_id: state.page.page.uuid,
            on_page,
            page_nr,
        }).then((responseData) => {
            dispatch(reviewsTotal(responseData.pages))
            if (Array.isArray(responseData.items)) {
                responseData.items.forEach((item) => {
                    dispatch(reviewsPush(item, page_nr))
                })
            }
        })
    }
}

