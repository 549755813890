import React from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import OfficesItem from './OfficesItem'

const Offices = ({page, offices}) => {
    const {t} = useTranslation()
    if (!page.uuid) {
        return null
    }

    return (
        <div className="sidebar_panel">
            <h3 className="sidebar_title">
                {t('sidebar:offices')}&nbsp;
                <span className="text-teal">{t('sidebar:officesTeal')}</span>
            </h3>
            <ul className="sidebar_offices">
                {offices.map((office, index) => (
                    <OfficesItem key={index} office={office}/>
                ))}
            </ul>
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    offices: state.page.offices,
})

export default connect(mapStateToProps)(Offices)
