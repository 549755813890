import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

const Registration = (props) => {
    const [registerType, setRegisterType] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const script = document.createElement('script')
        const widgetScript = process.env.REACT_APP_REGISTER_WIDGET_SCRIPT
        script.async = true
        script.src = `${widgetScript}?webpages=1`
        script.id = 'register-widget-script'
        const scriptExists = document.getElementById('register-widget-script')
        if (!scriptExists) {
            document.body.appendChild(script)
        }
        setTimeout(() => {
            if (!isLoaded) {
                dispatchEvent(new Event('load'))
                setIsLoaded(true)
            }
        }, 500)
    }, [])

    const getRegisterWidgetURL = () => {
        let registrationTypeUrl = null
        const registerType = props.registerType
        if (registerType === 'office') {
            registrationTypeUrl = 'office_registration'
        } else if (registerType === 'all') {
            registrationTypeUrl = 'index'
        } else if (registerType === 'catalog') {
            registrationTypeUrl = 'catalog'
        } else {
            registrationTypeUrl = ''
        }

        const urlParts = []
        urlParts.push(process.env.REACT_APP_REGISTER_LINK)
        urlParts.push(registrationTypeUrl)
        urlParts.push('?uuid=')
        urlParts.push(props.userUuid)

        return `${urlParts.join('')}&style=none&predefined=1`
    }
    return (
        <div className="register-widget-wrapper">
            <div
                id="medfile-register-widget"
                data-src={getRegisterWidgetURL()}
            ></div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    dataReady: state.page.dataReady,
    userUuid: state.page.user.uuid,
    registerType: state.page.page.data.register_online_type,
})

export default connect(mapStateToProps)(Registration)
