import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'
import DaysItem from './DaysItem'

const Days = ({page, daysData}) => {
    const {t} = useTranslation()
    const [daysList, setDaysList] = useState([])

    useEffect(() => {
        if (!page.uuid) {
            return null
        }
        const days = []

        if (typeof daysData === 'undefined') {
            return null
        }

        Object.entries(daysData).forEach(([weekDay, timeData]) => {
            const timeRanges = []
            for (let i = 0; i < timeData.from.length; i++) {
                timeRanges.push({
                    from: timeData.from[i],
                    to: timeData.to[i],
                })
            }
            days.push({
                weekDay: t(`days:day${weekDay}`),
                timeRanges,
            })
        })
        setDaysList(days)
    }, [page, daysData])

    if (!page.uuid || !daysList.length) {
        return null
    }
    return (
        <div className="sidebar_panel">
            <PageTitle text={t('sidebar:days')} teal={t('sidebar:daysTeal')}/>
            <ul className="sidebar_days_list">
                {daysList.map((day, index) => (
                    <DaysItem key={index} day={day}/>
                ))}
            </ul>
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    daysData: state.page.page.data.days,
})

export default connect(mapStateToProps)(Days)
