import {PAGE_REQUEST, PAGE_SUCCESS, PAGE_ERROR} from "./PageTypes";

const initialState = {
    isFetching: false,
    error: '',
    page: {
        uuid: '',
        title: '',
        meta_title: '',
        meta_keywords: '',
        content: '',
        scripts: '',
        header: '',
        footer: '',
        meta_description: '',
        tracking_number: '',
        data: {
            css_background_color: '',
            show_news: '',
            show_services: '',
            show_offices: '',
            register_online: '',
            register_online_type: '',
            show_reviews: '',
            show_offices_photo: '',
            show_experts: '',
            phone: '',
            email: '',
            street: '',
            house_number: '',
            postal_code: '',
            city: '',
            days: {},
            specializations: [],
            slide_image: '',
            logo_image: '',
        },
    },
    review_data: {
        average: "0",
        count: "0",
        sum: "0"
    },
    services: [],
    offices: [],
    user: {
        uuid: '',
        name: '',
        lastname: '',
        address_street: '',
        address_zip: '',
        address_city: '',
        address_home: '',
        address_place: '',
        company_name: '',
        company_nip: '',
        company_regon: '',
        company_phone: '',
        catalog_link: '',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case PAGE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.payload,
            }
        case PAGE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case PAGE_REQUEST:
            return {
                ...state,
                error: '',
                isFetching: true,
            }
        default:
            return state
    }
}
