import React from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

const ErrorPage = ({error}) => {
    const {t} = useTranslation()

    const onRefreshHandle = () => {
        window.location.reload()
    }

    return (
        <div className="app__error">
            <h2>{t('error:fetchErrorTitle')}</h2>
            <p>{t('error:fetchErrorDesc')}</p>
            <button onClick={onRefreshHandle}>
                {t('error:fetchErrorButton')}
            </button>
            {error || <Redirect to="/"/>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    error: state.page.error,
})

export default connect(mapStateToProps)(ErrorPage)
