import React from 'react'
import {connect} from 'react-redux'
import Mock from '../Mock'

const Banner = ({page, user}) => {
    if (!page.uuid) {
        return <Mock type="image"/>
    }

    if (!page.data.slide_image) {
        return null
    }

    const url = `${process.env.REACT_APP_ASSETS}${process.env.REACT_APP_DEFAULT_ASSETS_PATH}${user.uuid}/expert_photo/${page.data.slide_image}`
    return (
        <div className="banner">
            <img src={url} alt=""/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    user: state.page.user,
})

export default connect(mapStateToProps)(Banner)
