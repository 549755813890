import React from 'react'
import '../assets/scss/RatingStars.scss'

const RatingStart = (props = {}) => {
    const { rating = 1 } = props
    let stars = []
    let average = Math.round(rating)
    for (let rate = 1; rate <= 5; rate++) {
        if (rate <= average) {
            stars.push(<i className="icon-star text-teal" key={rate} />)
        } else {
            stars.push(<i className="icon-star-empty text-teal" key={rate} />)
        }
    }

    return (
        <div className="rating-stars">
            <div className="stars">{stars}</div>
        </div>
    )
}

export default RatingStart
