import {PAGE_REQUEST, PAGE_SUCCESS, PAGE_ERROR} from "./PageTypes";
import {newsPush} from "../News/NewsAction";
import Api from '../../utils/Api'

export const pageRequest = () => ({
    type: PAGE_REQUEST
})

export const pageError = (error = '') => ({
    type: PAGE_ERROR,
    payload: error,
})

export const pageSuccess = (payload) => ({
    type: PAGE_SUCCESS,
    payload,
})

//FOR FASTER DEVELOPMENT
const getLocationHref = () => {
    if (process.env.REACT_APP_ENV !== 'dev') {
        return window.location.href;
    }
    return 'http://' + process.env.REACT_APP_SUBDOMAIN + '.medfile.pl'
}

export const pageAction = () => {
    return async (dispatch) => {
        dispatch(pageRequest())
        const api = new Api()
        api.get('index', {url: getLocationHref()})
            .then((response) => {
                if (!response.page || response.page.uuid === false) {
                    dispatch(pageError(' '))
                    return
                }

                const {news} = response;
                delete response.news;
                dispatch(pageSuccess(response))
                if (Array.isArray(news)) {
                    news.forEach((article) => {
                        dispatch(newsPush(article, 1))
                    })
                }
            })
            .catch((r) => {
                dispatch(pageError(r))
            })
    }
}

