import {GALLERY_ERROR, GALLERY_REQUEST, GALLERY_SUCCESS} from "./GalleryTypes";
import Api from "../../utils/Api";

export const galleryRequest = () => ({
    type: GALLERY_REQUEST,
})

export const galleryError = (error) => ({
    type: GALLERY_ERROR,
    payload: error,
})

export const gallerySuccess = (payload) => ({
    type: GALLERY_SUCCESS,
    payload
})

export const galleryAction = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const api = new Api()
        galleryRequest()
        api.get('gallery', {
            user_id: state.page.user.uuid,
            page_id: state.page.page.uuid,
        }).then((response) => {
            if (Array.isArray(response)) {
                dispatch(gallerySuccess(response))
            }
        }).catch(e => {
            galleryError(e)
        })
    }
}