import React from 'react'

const GalleryItem = (props) => (
    <div className="cabinet-gallery__item" onClick={props.clickThumbnail}>
        <img src={process.env.REACT_APP_ASSETS + props.thumbnail} className="img-thumbnail" alt="" />
    </div>
)
GalleryItem.defaultProps = {
    clickThumbnail: () => {},
}

export default GalleryItem
