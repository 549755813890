import React from 'react'

import '../assets/scss/Mock.scss'

const Mock = (props) => {
    const words = (words = 16, extraCssClass = '', emptyElement = false) => {
        const output = []
        const cssClass = ['mock__word']
        extraCssClass && cssClass.push(extraCssClass)
        for (let i = 0; i < words; i++) {
            if (emptyElement) {
                output.push(<span key={i * 1000}></span>)
            } else {
                output.push(
                    <span className={cssClass.join(' ')} key={i}></span>
                )
            }
        }
        return output
    }

    return (
        <div className="mock">
            {words(props.unique || 0, undefined, true)}
            {props.type === 'title' && words(props.words || 1, 'mock--title')}
            {props.type === 'text' && words(props.words || 16)}
            {props.type === 'image' && <div className="mock__image"></div>}
            {props.type === 'panel' && (
                <div className="mock__panel">
                    {words(props.unique || 0, undefined, true)}
                    {words(props.words || 16)}
                </div>
            )}
        </div>
    )
}

Mock.defaultProps = {
    type: 'text',
}

export default Mock
