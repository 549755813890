import React from 'react'
import logo from '../assets/img/halodoctor.png'
export default () => {
    return (
        <div className="medfile-footer">
            <div className="footer-content">
                <img
                    src={logo}
                    alt={'https://www.medfile.pl/konsultacje-online'}
                />
                <span className="description">
                    Strona www gabinetu. Rezerwacja online. Kartoteka pacjenta.
                </span>

                <a
                    className="button"
                    href="https://www.medfile.pl/konsultacje-online"
                    target="blank"
                >
                    Zobacz więcej
                </a>
            </div>
        </div>
    )
}
