export const pl = {
    language: {
        label: 'English',
    },
    header: {
        registerVisit: 'Umów wizytę',
    },
    sidebar: {
        reviewViewAll: 'Zobacz wszystkie',
        reviewCount: 'opinii',
        facilityReview: 'opinii placówki',
        reviewEmpty: 'Powiedz nam co myślisz!',
        reviewAdd: 'Dodaj opinie',
        days: 'Godziny',
        daysTeal: 'przyjęć',
        services: 'Wykonywane',
        servicesTeal: 'usługi',
        sidebarTel: 'tel',
        offices: 'Nasze',
        officesTeal: 'gabinety',
        more: 'rozwiń'
    },
    container: {
        aboutTitle: 'O nas',
        expertsTitle: 'Specjaliści w naszej',
        expertsTitleTeal: 'placówce',
        galleryTitle: 'Nasza',
        galleryTitleTeal: 'galeria',
        showMore: 'zobacz więcej',
    },
    news: {
        titleHome: 'Aktualności',
        titleNews: 'Aktualności',
        moreHome: 'Zobacz wszystkie',
        backSingle: 'Wróć',
        backNews: 'Wróć',
        notFoundSingle: 'Nic nie znaleziono',
    },
    reviews: {
        titleReviews: 'Opinie',
        titleReviewsTeal: 'pacjentów',
        titleForm: 'Napisz swoją opinię',
        backHome: 'Powrót',
        officeLinkLabel: 'Gabinet',
        expertLinkLabel: 'Specialista',
        titleHome: 'Opinie',
        titleHomeTeal: 'pacjentów',
        moreHome: 'Zobacz wszystkie opinie',
        day: "dnia",
        hour: "o godzinie",
        noContent: 'brak treści'
    },
    days: {
        day1: 'Poniedziałek',
        day2: 'Wtorek',
        day3: 'Środa',
        day4: 'Czwartek',
        day5: 'Piątek',
        day6: 'Sobota',
        day7: 'Niedziela',
    },
    form: {
        processing: 'Przesyłanie formularza',
        afterPost: 'Wróć',
        labelName: 'Kim jesteś?',
        labelReview: 'Wyraź swoją opinię',
        labelSubmit: 'Dodaj opinię',
        errorName: 'Podaj imię',
        errorRate: 'Wybierz ocenę',
        errorReview: 'Wpisz treść',
    },
    error: {
        fetchErrorTitle: 'Wystąpił błąd podczas pobierania danych',
        fetchErrorDesc: 'Sprawdź adres url i odśwież stronę',
        fetchErrorButton: 'Odśwież',
    },
}
