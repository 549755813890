import {EXPERTS_ERROR, EXPERTS_REQUEST, EXPERTS_SUCCESS} from "./ExpertsTypes";

const initialState = {
    isFetching: false,
    error: '',
    items: [],
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case EXPERTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case EXPERTS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case EXPERTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.payload
            }
        default:
            return state
    }
}