import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PageTitle from '../PageTitle'
import GalleryItem from './GalleryItem'
import Mock from '../Mock'
import { loadGallery } from '../../actions'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { galleryAction } from '../../modules/actions'

const Gallery = ({ galleryAction, page, gallery }) => {
    const { t } = useTranslation()
    const [isFetched, setIsFetched] = useState(false)

    useEffect(() => {
        if (!isFetched && page.uuid) {
            galleryAction()
            setIsFetched(true)
        }
    })

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
    const [lightboxCurrentImage, setLightboxCurrentImage] = useState(0)

    const thumbnailClickHandle = (thumbnailIndex) => {
        setLightboxIsOpen(true)
        setLightboxCurrentImage(thumbnailIndex)
    }

    if (!isFetched) {
        return (
            <div>
                <Mock type="title" />
                <div className="cabinet-gallery">
                    <Mock type="image" />
                    <Mock type="image" />
                </div>
            </div>
        )
    }

    if (!gallery.length) {
        return null
    }

    return (
        <>
            {lightboxIsOpen && (
                <Lightbox
                    mainSrc={process.env.REACT_APP_ASSETS + gallery[lightboxCurrentImage].photo}
                    prevSrc={
                        lightboxCurrentImage === 0
                            ? null
                            : process.env.REACT_APP_ASSETS + gallery[Math.max(lightboxCurrentImage - 1, 0)].photo
                    }
                    nextSrc={
                        lightboxCurrentImage === gallery.length - 1
                            ? null
                            : process.env.REACT_APP_ASSETS + gallery[
                                  Math.min(
                                      lightboxCurrentImage + 1,
                                      gallery.length - 1
                                  )
                              ].photo
                    }
                    onCloseRequest={() => {
                        setLightboxIsOpen(false)
                    }}
                    onMovePrevRequest={() => {
                        setLightboxCurrentImage(
                            Math.max(lightboxCurrentImage - 1, 0)
                        )
                    }}
                    onMoveNextRequest={() => {
                        setLightboxCurrentImage(
                            Math.min(
                                lightboxCurrentImage + 1,
                                gallery.length - 1
                            )
                        )
                    }}
                />
            )}
            <PageTitle
                text={t('container:galleryTitle')}
                teal={t('container:galleryTitleTeal')}
            />
            <div className="cabinet-gallery">
                {gallery.map((image, index) => (
                    <GalleryItem
                        thumbnail={image.miniature}
                        key={index}
                        clickThumbnail={() => {
                            thumbnailClickHandle(index)
                        }}
                    />
                ))}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    gallery: state.gallery.items,
})

export default connect(mapStateToProps, { galleryAction })(Gallery)
