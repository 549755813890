import React from 'react'

const LabelLink = ({ label, link, prefix } = {}) => {
    const linkJsx = link ? (
        <a href={link} target="_blank" rel="noopener">
            {label}
        </a>
    ) : (
        <span>{label}</span>
    )

    return (
        <>
            {prefix && <span>{prefix}</span>} {linkJsx}
        </>
    )
}
LabelLink.defaultProps = {
    label: '',
    link: undefined,
    prefix: undefined,
}

export default LabelLink
