import React from 'react'
import {connect} from 'react-redux'
import Reviews from './Reviews'
import Days from './Days'
import Services from './Services'
import Offices from './Offices'
import Mock from '../Mock'
import '../../assets/scss/sidebar.scss'

const Sidebar = ({page}) => {
    if (!page.uuid) {
        return (
            <div>
                <Mock type="panel" words={4}/>
                <Mock type="panel" words={32} unique={1}/>
                <Mock type="panel" words={32} unique={2}/>
                <Mock type="panel" words={14} unique={3}/>
            </div>
        )
    }

    return (
        <div className="app__sidebar">
            {page.data.show_reviews > 0 && <Reviews/>}
            <Days/>
            {page.data.show_services > 0 && <Services/>}
            {page.data.show_offices > 0 && <Offices/>}
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
})

export default connect(mapStateToProps)(Sidebar)
