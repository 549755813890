import {useEffect} from 'react'
import {connect} from 'react-redux'

const CustomizePage = ({bgColor, title} = {}) => {
    useEffect(() => {
        if (bgColor) {
            document.body.style.backgroundColor = bgColor
        }

        if (title) {
            document.title = title
        }
    }, [bgColor, title])

    return null
}

const mapStateToProps = (state) => {
    return {
        bgColor: state.page.page.data.css_background_color,
        title: state.page.page.meta_title,
    }
}

export default connect(mapStateToProps)(CustomizePage)
