import React from 'react'
import { useTranslation } from 'react-i18next'
import RatingStart from '../RatingStars'
import LabelLink from '../LabelLink'
import dayjs from 'dayjs'
import '../../assets/scss/ReviewItem.scss'

const ReviewItem = ({ review } = {}) => {
    const { t } = useTranslation()
    const { username, content, rate, created_at, type, link, title } = review
    return (
        <article className="review-item">
            <div style={{    float: "right", marginTop: 10}}>
                <RatingStart rating={rate} />
            </div>
            <blockquote className="review-item-quote" >
                {content ? content : '- '+ t('reviews:noContent')}
                <footer>
                    - {username} {t('reviews:day')} {
                        dayjs(created_at, 'YYYY-MM-DD HH:mm:ss').format(
                        'YYYY-MM-DD'
                        )
                    } {t('reviews:hour')} {dayjs(created_at, 'YYYY-MM-DD HH:mm:ss').format(
                    'HH:mm'
                    )}
                </footer>
            </blockquote>

            {/*{type === 'page' && (*/}
            {/*    <LabelLink*/}
            {/*        label={title}*/}
            {/*        link={link}*/}
            {/*        prefix={t('reviews:officeLinkLabel')}*/}
            {/*    />*/}
            {/*)}*/}
            {/*{type === 'user' && (*/}
            {/*    <LabelLink*/}
            {/*        label={review.profile}*/}
            {/*        link={review.profile_link}*/}
            {/*        prefix={t('reviews:expertLinkLabel')}*/}
            {/*    />*/}
            {/*)}*/}
        </article>
    )
}

export default ReviewItem
