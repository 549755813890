import React, { useEffect, useRef } from 'react'

const ScrollTo = ({ scroll }) => {
    const anchor = useRef()
    useEffect(() => {
        if (scroll) {
            const top = anchor.current.getBoundingClientRect().top
            window.scrollTo({
                top,
                left: 0,
                behavior: 'smooth',
            })
        }
    }, [scroll])
    return <div ref={anchor}></div>
}
ScrollTo.defaultProps = {
    scroll: false,
}

export default ScrollTo
