import {GALLERY_ERROR, GALLERY_REQUEST, GALLERY_SUCCESS} from "./GalleryTypes";

const initialState = {
    isFetching: false,
    error: '',
    items: [],
}

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GALLERY_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            }
        case GALLERY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload
            }
        case GALLERY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                items: action.payload
            }


        default:
            return state
    }
}