import React, {useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'

const Services = ({page, services}) => {
    const {t} = useTranslation()
    const [showList, setShowList] = useState(false)

    if (!page.uuid) {
        return null
    }

    if (!services || !services.length) {
        return null
    }


    const renderService = () => {
        const output = []
        services.forEach((service, index) => {
            output.push(
                <li
                    key={index}
                    style={index > 9 && !showList ? {display: 'none'} : {}}
                >
                    <div className="pull-right">{service.price}</div>
                    {service.remote && (
                        <i className="icon icon-phone text-halodoctor"></i>
                    )}
                    {service.name}
                </li>
            )
        })
        return output
    }

    return (
        <div className="sidebar_panel">
            <PageTitle
                text={t('sidebar:services')}
                teal={t('sidebar:servicesTeal')}
            />
            <ul className="sidebar_services_list">{renderService()}</ul>
            {
                services.length > 10 &&
                <span style={{fontSize: 12, cursor: "pointer"}} onClick={() => {setShowList(!showList)}}>{showList ? '' : t('sidebar:more')}</span>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    page: state.page.page,
    services: state.page.services,
})

export default connect(mapStateToProps)(Services)
