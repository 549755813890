import React from 'react'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate'
import ReactHtmlParser from 'react-html-parser'
import dayjs from 'dayjs'

const NewsItem = ({ news } = {}) => {
    const { uuid, title, lead, created_at, text } = news
    return (
        <article className="news__item">
            <Link to={`/news/view/${uuid}`}>
                <h3 className="news__item__title">
                    <Truncate lines={1}>{ReactHtmlParser(title)}</Truncate>
                </h3>
                <span className="news__item__date">
                    {dayjs(created_at, 'YYYY-MM-DD HH:mm:ss').format(
                        'YYYY-MM-DD HH:mm'
                    )}
                </span>
                <div className="news__item__content">
                    {lead && (
                        <p className="lead">
                            <strong>{ReactHtmlParser(lead)}</strong>
                        </p>
                    )}

                    <Truncate lines={3}>{ReactHtmlParser(text)}</Truncate>
                </div>
            </Link>
        </article>
    )
}
export default NewsItem
