import {NEWS_ERROR, NEWS_REQUEST, NEWS_PUSH, NEWS_TOTAL} from "./NewsTypes";
import Api from "../../utils/Api";

export const newsRequest = () => ({
    type: NEWS_REQUEST,
})

export const newsError = (error) => ({
    type: NEWS_ERROR,
    payload: error,
})

export const newsTotal = (total) => ({
    type: NEWS_TOTAL,
    payload: total,
})
export const newsPush = (article, page = 1) => {
    return {type: NEWS_PUSH,
    payload: {
        ...article,
        page: parseInt(page),
    }}
}

export const newsAction = (page_nr = 1, on_page = 2) => {
    return async(dispatch, getState) => {
        dispatch(newsRequest())
        const state = getState()
        const api = new Api()
        api.get('news', {
            user_id: state.page.user.uuid,
            page_id: state.page.page.uuid,
            on_page,
            page_nr,
        }).then((responseData) => {
            dispatch(newsTotal(responseData.pages))
            if (Array.isArray(responseData.items)) {
                responseData.items.forEach((article) => {
                    dispatch(newsPush(article, page_nr))
                })
            }
        }).catch(error => {
            dispatch(newsError(error))
        })
    }
}
