export const en = {
    language: {
        label: 'Polski',
    },
    header: {
        registerVisit: 'Register visit',
    },
    sidebar: {
        reviewViewAll: 'Show all reviews',
        reviewCount: 'reviews',
        facilityReview: 'facility reviews',
        reviewEmpty: 'No reviews',
        reviewAdd: 'Add review',
        days: 'Office',
        daysTeal: 'hours',
        services: 'Our',
        servicesTeal: 'services',
        sidebarTel: 'phone',
        offices: 'Our',
        officesTeal: 'offices',
        more: 'show more'
    },
    container: {
        aboutTitle: 'About',
        expertsTitle: 'Our',
        expertsTitleTeal: 'experts',
        galleryTitle: 'Our',
        galleryTitleTeal: 'gallery',
        showMore: 'see more',
    },
    news: {
        titleHome: 'News',
        titleNews: 'News',
        moreHome: 'See all',
        backSingle: 'Back',
        backNews: 'Back',
        notFoundSingle: 'Nothing found',
    },
    reviews: {
        titleReviews: 'Reviews',
        titleReviewsTeal: 'patients',
        titleForm: 'Write your review',
        backHome: 'Back',
        officeLinkLabel: 'Office',
        expertLinkLabel: 'Expert',
        titleHome: 'Opinions',
        titleHomeTeal: 'patients',
        moreHome: 'See all reviews',
        day: "day",
        hour: "at hour",
        noContent: 'empty content'
    },
    days: {
        day1: 'Monday',
        day2: 'Tuesday',
        day3: 'Wednesday',
        day4: 'Thursday',
        day5: 'Friday',
        day6: 'Saturday',
        day7: 'Sunday',
    },
    form: {
        processing: 'Submiting review',
        afterPost: 'Back',
        labelName: 'Name',
        labelReview: 'Rate',
        labelSubmit: 'Submit',
        errorName: 'Missing name',
        errorRate: 'Missing rate',
        errorReview: 'Missing review',
    },
    error: {
        fetchErrorTitle: 'There was an error fetching the data',
        fetchErrorDesc: 'Check URL and try again',
        fetchErrorButton: 'Refresh',
    },
}
