import React from 'react'
import Language from './Language'
import Logo from './Logo'
import Title from './Title'
import RegisterButton from './RegisterButton'
import '../../assets/scss/header.scss'

const Header = () => {
    return (
        <header className="app__header">
            <Logo />
            <Title />
            <RegisterButton />
            <Language />
        </header>
    )
}

export default Header
