import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'
import Mock from '../Mock'
import {loadExperts} from '../../actions'
import ExpertsItem from './ExpertsItem'
import {expertsAction} from "../../modules/actions";

const Experts = ({expertsAction, page, experts}) => {
    const {t} = useTranslation()

    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        if (page.uuid && !isFetched) {
            expertsAction()
            setIsFetched(true)
        }
    },)

    if (!isFetched) {
        return (
            <>
                <Mock type="title"/>
                <div className="experts-grid">
                    <Mock type="panel"/>
                    <Mock type="panel"/>
                </div>
            </>
        )
    }

    if (!experts.length) {
        return null;
    }

    return (
        <>
            <PageTitle
                text={t('container:expertsTitle')}
                teal={t('container:expertsTitleTeal')}
            />
            <div className="experts-grid">
                {experts.map((expert, index) => (
                    <ExpertsItem key={index} expert={expert}/>
                ))}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {

    return {
        page: state.page.page,
        experts: state.experts.items
    }

    // dataReady: state.app.dataReady,
    // showExperts: state.app.page.data.show_experts,
    // experts: state.app.experts,
}

export default connect(mapStateToProps, {expertsAction})(Experts)
