import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import PageTitle from '../PageTitle'
import ReviewItem from './ReviewItem'
import Mock from '../Mock'
import {reviewsLoadLastAction} from '../../modules/actions'

const ReviewsLast = ({reviewsLoadLastAction, reviews, page, link}) => {
    const {t} = useTranslation()
    const [isFetched, setIsFetched] = useState(false)

    useEffect(() => {
        if (!isFetched && page.uuid) {
            reviewsLoadLastAction()
            setIsFetched(true)
        }
    })

    if (!isFetched) {
        return (
            <div>
                <Mock type="title" unique={2}/>
                <div className="reviews-grid">
                    <Mock type="panel"/>
                    <Mock type="panel"/>
                    <Mock type="panel"/>
                    <Mock type="panel"/>
                </div>
            </div>
        );
    }

    if (!reviews.length) {
        return null;
    }

    return (
        <div className="panel">
            <PageTitle
                text={t('reviews:titleHome')}
                teal={t('reviews:titleHomeTeal')}
            />
            <div className="reviews-grid">
                {reviews.map((review, index) => (
                    <ReviewItem
                        review={{
                            ...review,
                            link: link,
                            title: page.title,
                        }}
                        key={index}
                    />
                ))}
            </div>
            <div className="reviews__more">
                <Link className="text-link" to="/reviews">
                    {t('reviews:moreHome')}
                </Link>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    page: state.page.page,
    reviews: state.reviews.items,
    link: state.page.user.catalog_link
})

export default connect(mapStateToProps, {reviewsLoadLastAction})(ReviewsLast)
